.input {
    &--more {
        border-radius: 0;
        padding: 0;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        height: 32px;

        >.ant-input {
            margin-left: 8px;
            height: auto;
        }
    }

    &--suffix {
        color: gray;
        border-left: 1px solid gray;
        height: 100%;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
    }
}