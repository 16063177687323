.native-select {
    &--container {
        width: 100%;
        height: 32px;
        font-size: 14px;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        border-radius: 0;
        border: none;
        padding: 0 8px;
        background: transparent;
        color: black;
        appearance: none;
    }

    &--arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 12px;

        > svg {
            width: 12px;
            height: 12px;
        }
    }
}