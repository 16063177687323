#veesual_switch_model_root .button,
#veesual_switch_model_modal_root .button {
    &--outlined {
        border: 1px solid;
        width: 100%;
        margin-bottom: 16px;
        font-weight: bold;
    }

    &--icon {
        border: none;
        background-color: transparent;
        height: 100%;
    }
}