#veesual_switch_model_modal_root .card-carousel {
    $el: &;

    &--container {
        border: none;
        cursor: default;
        border-radius: 0;
        box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
        overflow: auto;
        width: auto;
        margin: 8px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .ant-card-cover {
            height: 100%;
            width: 100%;
            overflow: hidden;

            .ant-image {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;

                .ant-image-img {
                    height: 100%;
                    object-fit: cover;
                    width: auto;
                }
            }
        }

        .ant-card-body {
            padding: 8px;
            flex: 1;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            gap: 8px;

            &::before,
            &::after {
                display: none;
            }
        }
    }

    &--container:hover .card-carousel--look-option {
        opacity: 1;
    }

    &--body-container {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &--cta-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
    }

    &--more-container {
        @extend .card-carousel--container;
        display: flex;
        height: 100%;
        width: 100%;

        .ant-card-body {
            padding: 0;
            width: 100%;
        }
    }

    &--more-image {
        background-size: contain;
        transition: all 1s ease-out 0s;
        opacity: 0.4;
    }

    &--plus-icon {
        position: absolute;
        color: gray;
    }

    &--image {
        object-fit: cover;
        margin-bottom: 8px;
        width: 100%;
        height: 100%;
        border-radius: 16px 16px 0 0;
    }

    &--image-container {
        height: 100%;
        position: relative;
      }

    &--title {
        @extend %title-base;

        font-size: 20px !important;
        line-height: 25px !important;
        margin-bottom: 8px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &--text {
        text-align: left;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 12px;
        height: 38px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &--button {
        min-height: 33px;
        width: 115px;
        border-radius: 8px;
        color: $background;
        border: none;
        margin-bottom: 10px;
    }

    &--hide {
        display: none;
    }

    &--swipe {
        justify-content: center;
        align-items: center;
        display: flex;
        height: 180px;
        background-color: transparent;
        border: none;
        margin: 0;
    }

    &--swipe-image {
        object-fit: contain;
        margin-bottom: 10px;
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;

        &.swipe-format {
            object-fit: cover;
            margin-bottom: 0;
            width: auto;
        }
    }

    &--more-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: 100%;
        padding: 8px;
    }

    &--text.card-carousel--multisize {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
    }

    &--look-option {
        position: absolute;
        opacity: 1;

        @include breakpoints(sm) {
            opacity: 0;
            transition: opacity 0.3s;
        }

        bottom: 0;
        width: 100%;
        height: 32px;
    }

    &--look-option-container {
        display: flex;
        justify-content: flex-end;
        padding: 0px 8px;
        height: 100%;
    }
}

%title-base {
    font-weight: bold !important;

    ::first-letter {
        text-transform: uppercase;
    }
}