#veesual_switch_model_root .custom-radio-group,
#veesual_switch_model_modal_root .custom-radio-group {
    &--container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &--label {
        width: fit-content;
        max-width: 100%;
        padding: 0 8px;
        font-size: 11px;
        border: none;
    }

    &--button-container {
        width: 100%;
        justify-content: space-between;
        column-gap: 8px;
    }

    &--button {
        flex: 1;
        border: 1px solid;
        border-color: black;
        font-size: 12px;
        padding: 0;
    }

    &--button-active {
        @extend .custom-radio-group--button;

        border-color: var(--primary-color);
        color: var(--primary-color);
    }

    &--button-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 8px;
        display: flex;
    }
}