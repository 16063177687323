.form-more {
    &--container {
        justify-content: space-between;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &--success-container {
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    &--success-icon {
        font-size: 80px;
        color: $successcolor;
        justify-content: center;
    }

    &--title {
        font-size: 30px !important;
        text-align: center;
    }

    &--description {
        text-align: center;
        white-space: pre-line;
    }

    &--form {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .ant-input {
            width: 100%;
            background-color: white;
        }

        .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item {
            width: 100%;
        }
    }

    &--form-input-container {
        padding: 0px 8px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}